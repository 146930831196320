<template lang="pug">
.seo
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head';

interface IProps {
  seo?: any;
}

const props = withDefaults(defineProps<IProps>(), {
  seo: null,
});

useHead({
  title: props.seo?.metaTitle,
  meta: [
    {
      name: 'title',
      content: props.seo?.metaTitle,
    },
    {
      name: 'description',
      content: props.seo?.metaDescription,
    },
    props.seo?.shareImage
      ? {
        property: 'og:image',
        content: props.seo?.shareImage.url,
        }
      : {},
  ].filter((meta) => Object.keys(meta).length > 0),
});
</script>
